@font-face {
  font-family: "Marsek Demi";
  font-style: normal;
  font-weight: normal;
  src: local("Marsek Demi"),
    url("./Assets/fonts/Marsek-Demi.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600;700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  zoom: 90%;
}

header {
  background-color: #10131a;
  padding: 30px 0 150px;
}

nav {
  padding: 0px 30px 100px 111px;
}

nav img {
  width: 27px;
  height: 26px;
}

header section > div {
  display: block;
  width: 100%;
  text-align: center;
  height: auto;
}

header section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

header section img {
  width: 15%;
}

header section > div:first-of-type {
  padding: 20px 0;
  font-family: Marsek Demi;
  font-size: 48px;
  line-height: 46px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.29) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

header section > div:last-of-type {
  font-family: Marsek Demi;
  font-size: 28px;
  line-height: 27px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  background-image: linear-gradient(
    180deg,
    #21d0f4 0%,
    rgba(33, 208, 244, 0.38) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.desc {
  padding: 62px 260px 77px;
}

.desc > div:first-of-type {
  padding-bottom: 100px;
}

.heading {
  font-family: Marsek Demi;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  text-transform: uppercase;
  padding-bottom: 16px;
}
.content {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #898989;
  /* padding-bottom: 100px; */
}

.card {
  border: 0;
  /* height: 277px; */
}

.cardLeft > .card-body {
  border-radius: 16px 0px 0px 16px;
  background: #010c1e;
  padding: 32px 38px;
  height: 290px;
  max-width: 500px;
  margin-right: 0;
  margin-left: auto;
}

.cardLeft .card-title {
  font-family: Marsek Demi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  padding-bottom: 16px;
}

.cardLeft .card-text {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #898989;
  margin-bottom: 10px;
}

.cardLeft .card-body button {
  border-radius: 27.2742px;
  border: 2px solid #21d0f4;
  background-color: transparent;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  padding: 8px 10px;
}
.cardRight img {
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.stats {
  background-color: #010c1e;
  padding: 123px 147px;
  margin-bottom: 44px;
}

.stats > div {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.stats > div > span:first-child {
  font-family: Marsek Demi;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding-bottom: 17px;
}
.stats > div > span:last-child {
  font-family: Marsek Demi;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(214, 214, 214, 0.83);
}
.tweets {
  padding: 0 211px 145px;
  text-align: center;
}
.twitterCard {
  text-align: center;
  margin: auto;
  height: 800px !important;
  width: 500px !important;
  overflow: auto;
  margin-top: 50px;
  /* border: 2px solid rgb(146, 146, 146); */
  border-radius: 10px;
  box-shadow: 1px 10px 50px 0px rgba(146, 146, 146, 0.527);
}

/* temporary */
.tweets img {
  margin-top: 80px;
}
/* footer */
footer {
  background-color: black;
  width: 100%;
  padding: 31px 0;
}
.copyright {
  font-family: Marsek Demi;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #484850;
}
footer img {
  width: auto;
  height: 23px;
}
@media only screen and (max-width: 1024px) {
  .desc {
    padding: 62px 40px 77px;
  }
  .cardLeft > .card-body {
    height: 100%;
  }
  .stats {
    padding: 60px 50px;
  }
  .tweets {
    padding: 0 50px 50px;
  }
}
@media only screen and (max-width: 768px) {
  .desc {
    padding: 62px 40px 77px;
  }
  .cardLeft > .card-body {
    height: auto;
  }
  .stats {
    padding: 60px 50px;
  }
  .stats > div {
    height: 100%;
  }
  .tweets {
    padding: 0 50px 50px;
  }
  .tweets img {
    width: 90vw;
  }
}

@media only screen and (max-width: 767px) {
  header {
    padding: 11px 0 45px;
  }

  nav {
    padding: 0px 30px 35px 73px;
  }

  nav img {
    width: 12px;
    height: 11px;
  }

  header section img {
    width: 60px;
  }

  header section > div:first-of-type {
    padding: 16px 0 4px;
    font-size: 20px;
    line-height: 19.14px;
  }

  header section > div:last-of-type {
    font-size: 12px;
    line-height: 11.48px;
  }

  .desc {
    padding: 24px 50px 38px;
  }

  .desc > div:first-of-type {
    padding-bottom: 25px;
  }

  .heading {
    font-size: 16px;
    line-height: 15.31px;
    padding-bottom: 4px;
  }
  .content {
    font-size: 12px;
    line-height: 16.37px;
    color: #898989;
  }

  .cardLeft > .card-body {
    border-radius: 8px 8px 0px 0px;
    background: #010c1e;
    padding: 16px 17px;
    height: 100%;
  }

  .cardLeft .card-title {
    font-size: 14px;
    line-height: 13.34px;
    padding-bottom: 5px;
  }

  .card-body > p.card-text {
    font-size: 12px;
    line-height: 16.37px;
    margin: 0 0 7px;
    padding: 0;
  }

  .cardLeft .card-body button {
    border-radius: 13px;
    border: 1px solid #21d0f4;
    font-size: 10px;
    line-height: 13.64px;
    padding: 6px 14px;
  }

  .cardRight img {
    width: 100%;
	border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .stats {
    padding: 40px 0 0;
    margin-bottom: 37px;
    flex-wrap: wrap;
    text-align: center;
  }

  .stats > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 43px;
  }

  .stats > div > span:first-child {
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 5px;
  }
  .stats > div > span:last-child {
    font-size: 14px;
    line-height: 14px;
  }

  .tweets {
    padding: 0 50px 50px;
  }
  .twitterCard {
    width: auto !important;
  }
  /* temporary */
  .tweets img {
    margin-top: 20px;
    width: 100%;
  }
  /* footer */
  footer {
    padding: 28px 44px;
  }
  .copyright {
    font-size: 10px;
    line-height: 10px;
  }
  footer img {
    width: 17px;
    height: 14px;
  }
}
